<template>
    <main class="main-page">
    <template v-if="showHeader">
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid align-items-center">
                    <div  v-if="!isSubPage"  class="col-fixed " >
                        <Button @click="$router.go(-1)" label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                    </div>
                    <div  class="col " >
                        <div class="" >
                            <div class="flex align-items-center ">
                                <div class="mr-3">
                                    <Avatar size="large" class="" icon="pi pi-plus" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Send New Notification</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div  class="md:col-9 sm:col-12 comp-grid" >
                        <div >
                            <form ref="observer" tag="form" @submit.prevent="submitForm()" :class="{ 'card': !isSubPage }" class="">
                                <div class="grid">
                                    <div class="col-12 md:col-6">
										<div class="mb-2 font-bold"> Title *</div>
                                        <div id="ctrl-title-holder"> 
                                            <InputText ref="ctrltitle" v-model.trim="formData.title"  label="Title" type="text" placeholder="Enter Title"      
                                            class=" w-full" :class="getErrorClass('title')">
                                            </InputText>
                                            <small v-if="isFieldValid('title')" class="p-error">{{ getFieldError('title') }}</small> 
                                        </div>
                                    </div>
									<div class="col-12 md:col-6">
										<div class="mb-2 font-bold"> Search and select recipient * </div>
                                        <div id="ctrl-user_id-holder"> 
                                            <auto-complete-select :class="getErrorClass('user_id')" api-path="components_data/user_id_option_list" forceSelection ref="ctrluser_id"  v-model="formData.user_id" label="Search and select recipient" >
                                                </auto-complete-select>
                                                <small v-if="isFieldValid('user_id')" class="p-error">{{ getFieldError('user_id') }}</small> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
										<div class="mb-2 font-bold"> Message *</div>
                                        <div id="ctrl-message-holder"> 
                                            <Textarea :class="getErrorClass('message')" class="w-full" ref="ctrlmessage" rows="3"  v-model="formData.message" placeholder="Enter Message"    type="textarea">
                                            </Textarea>
                                            <small v-if="isFieldValid('message')" class="p-error">{{ getFieldError('message') }}</small> 
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useAddPage } from '@/composables/addpage.js';
	const props = defineProps({
		pageName : {
			type : String,
			default : 'notifications',
		},
		routeName : {
			type : String,
			default : 'notificationsadd',
		},
		apiPath : {
			type : String,
			default : 'notifications/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Create Record',
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	const app = useApp();
	const formDefaultValues = {
		title: "", 
		message: "", 
		user_id: "", 
	};
	const formData = reactive({ ...formDefaultValues });
	//form validation rules
	const rules = computed(() => {
		return {
			title: { required },
			message: { required },
			user_id: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, beforeSubmit, afterSubmit });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit(){
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		page.setFormDefaultValues(); //reset form data
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/notifications`);
		}
	}
	const { submitted, saving, pageReady } = toRefs(page.state);
	const { submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(()=>{
		const pageTitle = "Send New Notification";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
